import RED_BRICK_IMAGE from './images/brick-red.png';
import BLUE_BRICK_IMAGE from './images/III_KLOCEK_NIEBIESKI.png';
import GREEN_BRICK_IMAGE from './images/III_KLOCEK_ZIELONY.png';
import YELLOW_BRICK_IMAGE from './images/brick-yellow.png';
import PURPLE_BRICK_IMAGE from './images/III_KLOCEK_ZOLTY.png';
import DUAL_BALL_IMAGE from './images/PILKA_BLUE_GREEN.png';
import BLUE_BALL_IMAGE from './images/PILKA_BLUE.png';
import GREEN_BALL_IMAGE from './images/III_KULKA.png';
import DUAL_PADDLE_IMAGE from './images/PADDLE_BLUE_GREEN.png';
import BLUE_PADDLE_IMAGE from './images/PADDLE_BLUE.png';
import GREEN_PADDLE_IMAGE from './images/III_PADDLE.png';


// Grab the canvas element for calculating the brick width
// depending on canvas width
const canvas: HTMLCanvasElement | null = document.querySelector('#playField');

// Constants
export const LIVES = 3;
export const STAGE_PADDING = 5;
export const STAGE_ROWS = 10;
export const STAGE_COLS = 8;
export const BRICK_PADDING = 0;
export const BRICK_WIDTH = Math.floor((window.innerWidth ) / STAGE_COLS);
export const BRICK_HEIGHT = Math.floor((window.innerWidth) / STAGE_ROWS);
export let PADDLE_WIDTH = window.innerWidth / 4;
export let PADDLE_HEIGHT = 25;
if(PADDLE_WIDTH > 150){
    PADDLE_WIDTH = 150;
}
export const PADDLE_STARTX = window.innerWidth / 2 - (PADDLE_WIDTH / 2);
export const PADDLE_SPEED = window.innerWidth / 120;
export let BALL_SPEED =  window.innerHeight / 125;

if (BALL_SPEED < 3 && window.innerWidth <= 375) {
  BALL_SPEED = 3.5;
}
export let BALL_SIZE = 25;
export const BALL_STARTX = window.innerWidth / 2 - (BALL_SIZE  / 2);
export const BALL_STARTY = document.getElementsByTagName("html")[0].clientHeight - 50 - 150 - 70;

export const PADDLE_IMAGES: { [key: number]: string } = {
    1: DUAL_PADDLE_IMAGE,
    2: BLUE_PADDLE_IMAGE,
    3: GREEN_PADDLE_IMAGE
};

export const BALL_IMAGES: { [key: number]: string } = {
    1: DUAL_BALL_IMAGE,
    2: BLUE_BALL_IMAGE,
    3: GREEN_BALL_IMAGE
};

export const BRICK_IMAGES: { [key: number]: string } = {
  1: RED_BRICK_IMAGE,
  2: GREEN_BRICK_IMAGE,
  3: YELLOW_BRICK_IMAGE,
  4: BLUE_BRICK_IMAGE,
  5: PURPLE_BRICK_IMAGE
};

export const BRICK_ENERGY: { [key: number]: number } = {
  1: 1, // Red brick
  2: 2, // Green brick
  3: 3, // Yellow brick
  4: 3, // Blue brick
  5: 1 // Purple brick
};

// prettier-ignore
export const LEVEL = [
     0, 0, 0, 0, 0, 0, 0, 0,
     0, 0, 0, 0, 0, 0, 0, 0,
     0, 5, 5, 5, 5, 5, 5, 0,
     0, 0, 2, 2, 2, 2, 0, 0,
     0, 0, 0, 4, 4, 0, 0, 0,
];
