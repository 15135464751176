// Types
import { Brick } from './sprites/Brick';
import { Paddle } from './sprites/Paddle';
import { Ball } from './sprites/Ball';
import { CanvasView } from './view/CanvasView';
import {BRICK_IMAGES, PADDLE_IMAGES, BALL_IMAGES, PADDLE_WIDTH, BALL_SIZE} from './setup';

//neon
const neons = document.querySelectorAll('.neon_active');

export class Collision {
  isCollidingBrick(ball: Ball, brick: Brick) {
    if (
      ball.pos.x < brick.pos.x + brick.width &&
      ball.pos.x + ball.width > brick.pos.x &&
      ball.pos.y < brick.pos.y + brick.height &&
      ball.pos.y + ball.height > brick.pos.y
    ) {
      return true;
    }
    return false;
  }

  // Check ball collision with bricks
  isCollidingBricks(ball: Ball, bricks: Brick[]){
    let colliding = false;

    bricks.forEach((brick, i) => {
      if (this.isCollidingBrick(ball, brick)) {

        ball.changeYDirection();
        // @ts-ignore
        // bump.load();
        if (brick.energy === 1) {
          bricks.splice(i, 1);
          ball.image.src = BALL_IMAGES[1];
          neons.forEach((neon) => {
            neon.classList.add('active');
            setTimeout(() => {neon.classList.remove('active')},1000);
          })
        } else {
          brick.energy -= 1;
          ball.changeXDirection();
          if(brick.energy === 2){
            brick.image.src = BRICK_IMAGES[2];
            ball.image.src = BALL_IMAGES[2];
            neons[1].classList.add('active')
            setTimeout(() => {neons[1].classList.remove('active')},1000);
          }
          else if(brick.energy === 1){
            brick.image.src = BRICK_IMAGES[5];
            ball.image.src = BALL_IMAGES[3];
            neons[0].classList.add('active')
            setTimeout(() => {neons[0].classList.remove('active')},1000);
          }
        }
        colliding = true;
      }
    });
    return colliding;
  }

  checkBallCollision(ball: Ball, paddle: Paddle, view: CanvasView): void {
    const imageSrc = ball.image.src;
    // 1. Check ball collision with paddle
    if (
      ball.pos.x + ball.width > paddle.pos.x &&
      ball.pos.x < paddle.pos.x + paddle.width &&
      ball.pos.y + ball.height === paddle.pos.y
    ) {
      if(ball.pos.x < (paddle.pos.x + (PADDLE_WIDTH / 2 - BALL_SIZE / 2)) && ball.speed.x > 0){
        // console.log('lewo')
        // console.log(ball.speed.x)
        ball.speed.x = ball.speed.x * -1;
      }
      else if(ball.pos.x > (paddle.pos.x + (PADDLE_WIDTH) - (PADDLE_WIDTH / 2 - BALL_SIZE / 2)) && ball.speed.x < 0){
        // console.log('prawo')
        // console.log(ball.speed.x)
        ball.speed.x = ball.speed.x * -1;
      }
      ball.changeYDirection();
      if(imageSrc.search(BALL_IMAGES[2]) > 0){
        paddle.image.src = PADDLE_IMAGES[2]
      }
      else if(imageSrc.search(BALL_IMAGES[3]) > 0){
        paddle.image.src = PADDLE_IMAGES[3]
      }
      else{
        paddle.image.src = PADDLE_IMAGES[1]
      }
      if(ball.speed.x !== 0){
        // @ts-ignore
        // bump.play();
      }

    }
    // 2. Check ball collision with walls
    // Ball movement X constraints
    if (ball.pos.x > view.canvas.width - ball.width || ball.pos.x < 0) {
      ball.changeXDirection();
      // @ts-ignore
      // bump.play();
    }
    // Ball movement Y constraints
    if (ball.pos.y < 0) {
      ball.changeYDirection();
    }
  }
}
