import { Vector } from '../types';
import {PADDLE_IMAGES} from "~/setup";

export class Paddle {
  private paddleImage: HTMLImageElement = new Image();
  private moveLeft: boolean;
  private moveRight: boolean;

  constructor(
    private speed: number,
    private paddleWidth: number,
    private paddleHeight: number,
    private position: Vector,
    image: string
  ) {
    this.speed = speed;
    this.paddleWidth = paddleWidth;
    this.paddleHeight = paddleHeight;
    this.position = position;
    this.moveLeft = false;
    this.moveRight = false;
    this.paddleImage.src = PADDLE_IMAGES[1];
    // Event Listeners
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleMouseClick);
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  // Getters
  get width(): number {
    return this.paddleWidth;
  }

  get height(): number {
    return this.paddleHeight;
  }

  get pos(): Vector {
    return this.position;
  }

  get image(): HTMLImageElement {
    return this.paddleImage;
  }

  get isMovingLeft(): boolean {
    return this.moveLeft;
  }

  get isMovingRight(): boolean {
    return this.moveRight;
  }

  movePaddle(): void {
    if (this.moveLeft) this.pos.x -= this.speed;
    if (this.moveRight) this.pos.x += this.speed;
  }

  handleKeyUp = (e: KeyboardEvent): void => {
    if (e.code === 'ArrowLeft' || e.key === 'ArrowLeft') this.moveLeft = false

    if (e.code === 'ArrowRight' || e.key === 'ArrowRight')
      this.moveRight = false

  };

  handleMouseMove = (e: MouseEvent): void => {
    let timeout,
    that = this;

    if (e.movementX >= 1 )
      this.moveRight = true;
    else this.moveRight = false;
    if (e.movementX <= -1)
      this.moveLeft = true;
    else this.moveLeft = false;
    if (timeout) clearTimeout(timeout);
      timeout = setTimeout(mouseStop, 50);
    function mouseStop() {
      that.moveRight = false;
      that.moveLeft = false;
    }
  };

  touchHelperArray: any[] = [];
  clickHelperArray: any[] = [];

  handleMouseClick = (e: MouseEvent): void => {
    let whereClickStart = e.clientX,
        // @ts-ignore
        playFieldWidth = document.querySelector("#playField").getBoundingClientRect().width;

    this.clickHelperArray.push(whereClickStart);
    this.clickHelperArray.splice(0,this.clickHelperArray.length - 2);
    let that = this;


    function moveRight() {
      if(that.position.x < that.clickHelperArray[1] - (that.paddleWidth / 2)){
        that.position.x = that.position.x + (window.innerWidth / 80);
        setTimeout(moveRight,7);
      }
      if(that.position.x >= (playFieldWidth - that.paddleWidth)){
        that.position.x = (playFieldWidth - that.paddleWidth);
      }
      if(that.position.x < 0){
        that.position.x = 0;
      }
    }
    function moveLeft() {
      if(that.position.x > that.clickHelperArray[1] - (that.paddleWidth / 2)){
        that.position.x = that.position.x - (window.innerWidth / 80);
        setTimeout(moveLeft,7);
      }
      if(that.position.x >= (playFieldWidth - that.paddleWidth)){
        that.position.x = (playFieldWidth - that.paddleWidth);
      }
      if(that.position.x < 0){
        that.position.x = 0;
      }
    }

    if(this.clickHelperArray[1] > this.position.x){
      setTimeout(moveRight,7);
    }
    if(this.clickHelperArray[1] < this.position.x){
      setTimeout(moveLeft,7);
    }

  }

  handleTouchMove = (e: TouchEvent): void => {

    let paddlePosition = this.position.x,
        whereTouchedStart = e.changedTouches[0].clientX,
        // @ts-ignore
        playFieldWidth = document.querySelector("#playField").getBoundingClientRect().width;

    this.touchHelperArray.push(whereTouchedStart);
    this.touchHelperArray.splice(0,this.touchHelperArray.length - 2);

    this.position.x = this.touchHelperArray[1] - (this.paddleWidth / 2);


    if(this.position.x >= (playFieldWidth - this.paddleWidth)){
      this.position.x = (playFieldWidth - this.paddleWidth);
    }
    if(this.position.x < 0){
      this.position.x = 0;
    }

  };

  handleKeyDown = (e: KeyboardEvent): void => {
    if (e.code === 'ArrowLeft' || e.key === 'ArrowLeft')
      this.moveLeft = true;
    if (e.code === 'ArrowRight' || e.key === 'ArrowRight')
      this.moveRight = true;
  };
}
