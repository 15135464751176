// Types
import { Brick } from '../sprites/Brick';
import { Paddle } from '../sprites/Paddle';
import { Ball } from '../sprites/Ball';
import {inActiveGame, shield} from "../shield";

export class CanvasView {
  canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D | null;
  private scoresDisplay: NodeListOf<HTMLObjectElement> | null;
  private start: HTMLObjectElement | null;
  private info: HTMLObjectElement | null;

  constructor(canvasName: string) {
    this.canvas = document.querySelector(canvasName) as HTMLCanvasElement;
    this.context = this.canvas.getContext('2d');
    this.scoresDisplay = document.querySelectorAll('.score');
    this.start = document.querySelector('#start');
    this.info = document.querySelector('#info');
  }

  clear(): void {
    this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  initStartButton(startFunction: (view: CanvasView) => void): void {
    this.start?.addEventListener('click', () => {
      document.querySelector('#start').style.display = "none";
      document.querySelector('#lives').style.display = "flex";
      if(!shield()){
        inActiveGame()
      }
      startFunction(this)
    });
  }

  drawScore(score: number): void {
    if (this.scoresDisplay){
      this.scoresDisplay.forEach((scoreDisplay) =>{
       scoreDisplay.innerHTML = score.toString();
      })
    }
  }

  drawInfo(text: string): void {
    if (this.info) this.info.innerHTML = text;
  }

  drawSprite(brick: Brick | Paddle | Ball): void {
    if (!brick) return;

    this.context?.drawImage(
      brick.image,
      brick.pos.x,
      brick.pos.y,
      brick.width,
      brick.height
    );
  }

  drawBricks(bricks: Brick[]): void {
    bricks.forEach(brick => this.drawSprite(brick));
  }

}
