import { Vector } from '../types';
import {BALL_SPEED, BALL_IMAGES} from "~/setup";

export class Ball {
  speed: Vector;
  private ballImage: HTMLImageElement = new Image();

  constructor(
      speed: number,
      private ballSize: number,
      private position: Vector,
  ) {
    this.ballSize = ballSize;
    this.position = position;
    this.speed = {
      x: speed + (.3 + (Math.random() * 10) / 10),
      y: -speed
    };
    this.ballImage.src = BALL_IMAGES[1];
  }

  // Getters
  get width(): number {
    return this.ballSize;
  }

  get height(): number {
    return this.ballSize;
  }

  get pos(): Vector {
    return this.position;
  }

  get image(): HTMLImageElement {
    return this.ballImage;
  }


  // Methods
  changeYDirection(): void {
    this.speed.y = -this.speed.y;

  }

  changeXDirection(): void {
    this.speed.x = -this.speed.x;

  }
  startBall():void{
    this.speed.x = BALL_SPEED + (.3 + (Math.random() * 10) / 10);
    this.speed.y = -BALL_SPEED;
  }
  stopBall(): void {
    this.speed.x = 0;
    this.speed.y = 0;
  }

  moveBall(): void {
    this.pos.x += this.speed.x;
    this.pos.y += this.speed.y;
  }
}
