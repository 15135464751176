import { CanvasView } from './view/CanvasView';
import { Ball } from './sprites/Ball';
import { Brick } from './sprites/Brick';
import { Paddle } from './sprites/Paddle';
import { Collision } from './Collison';
import {shield,inActiveGame} from "./shield";


import './css/style.css'
import 'animate.css';


// Images
import {BALL_IMAGES, BRICK_IMAGES, PADDLE_IMAGES} from "./setup";
// Level and colors
import {
  PADDLE_SPEED,
  PADDLE_WIDTH,
  PADDLE_HEIGHT,
  PADDLE_STARTX,
  BALL_SPEED,
  BALL_SIZE,
  BALL_STARTX,
  BALL_STARTY,
  LIVES
} from './setup';
// Helpers
import { createBricks } from './helpers';



(function() {
    // shield check
    if(!shield()){
      inActiveGame()
    }
    else{
      // syf
      const canvas = document.getElementById('playField');
      const main = document.querySelector("#main");
      const video = document.querySelector('#video');
      const instructions = document.querySelectorAll(".before__cards-info ol li");
      const bgMusic = document.querySelector('#background_music');
      const lostLiveSound = document.querySelector('#live');
      const winGameSound = document.querySelector('#win');
      const startGameSound = document.querySelector('#start_music');
      const livesImages = document.querySelectorAll('#lives img');
      //instruction fade

      function instructionFade(){
        instructions.forEach(instruction => {
          instruction.classList.add('animate__fadeIn');
        })
      }
      //bg_music
       function musicPlay() {
         // @ts-ignore
         bgMusic.volume = 0.2;
         bgMusic.play();
         window.addEventListener('blur', () => {
           bgMusic.pause();
         });
         window.addEventListener('focus',  () => {
           bgMusic.play();
         });
       }

       //sounds
      const bumpURL = document.querySelector('#bump source').src;
      const winURL = document.querySelector('#win source').src;
      const liveURL = document.querySelector('#live source').src;


      const context = new AudioContext();

      let bumpBuffer: AudioBuffer;
      let winBuffer: AudioBuffer;
      let liveBuffer: AudioBuffer;

      window.fetch(bumpURL)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            bumpBuffer = audioBuffer;
          });

      window.fetch(winURL)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            winBuffer = audioBuffer;
          });

      window.fetch(liveURL)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            liveBuffer = audioBuffer;
          });


      function play(audioBuffer) {
        const source = context.createBufferSource();
        const gainNode = context.createGain();
        source.buffer = audioBuffer;
        gainNode.connect(context.destination);
        // source.connect(context.destination);
        source.connect(gainNode);
        gainNode.gain.value = 1;
        source.start();
      }
      
      function playMuted(audioBuffer){
        const source = context.createBufferSource();
        const gainNode = context.createGain();
        source.buffer = audioBuffer;
        gainNode.connect(context.destination);
        // source.connect(context.destination);
        source.connect(gainNode);
        gainNode.gain.value = 0;
        source.start();
      }

      //video
      // @ts-ignore
      video.play();
      // @ts-ignore
      video.addEventListener('ended',() => video.parentElement.classList.add("animate__fadeOutLeftBig") ,false);
      // @ts-ignore
      const ballImagesArray = Object.keys(BALL_IMAGES).map((key) => BALL_IMAGES[key]);
      // @ts-ignore
      const paddleImagesArray = Object.keys(PADDLE_IMAGES).map((key) => PADDLE_IMAGES[key]);
      // @ts-ignore
      const brickImagesArray = Object.keys(BRICK_IMAGES).map((key) => BRICK_IMAGES[key]);
      // @ts-ignore
      const context = canvas.getContext('2d');
      // document.addEventListener("DOMContentLoaded", loadImages, true);
      // function loadImages(e) {
      //
      //   for (var i = 0; i < e.length; i++) {
      //     var tempImage = new Image();
      //     tempImage.src = e[i];
      //   }
      // }
      //
      // // Load images
      //
      // ballImagesArray.forEach(image => loadImages(image));
      // paddleImagesArray.forEach(image => loadImages(image));
      // brickImagesArray.forEach(image => loadImages(image))

      let noGameCards = document.querySelectorAll(".card");
      // @ts-ignore
      main.style.height = `${document.getElementsByTagName("html")[0].clientHeight}px`;

      noGameCards.forEach((card) => {
        card.style.height = `${document.getElementsByTagName("html")[0].clientHeight}px`;
        card.addEventListener("click",resizeCanvas);
      })
      //koniec syfu
      // resize the canvas to fill browser window dynamically
      window.addEventListener('resize', resizeCanvas, false);

      function resizeCanvas(e: any) {
        // @ts-ignore
        canvas.width = document.getElementsByTagName("html")[0].clientWidth;
        // @ts-ignore
        canvas.height = document.getElementsByTagName("html")[0].clientHeight - 70;
        console.log(shield());
        if(e.target.closest(".scrollDown") && shield()){
          if(e.target.closest(".before__cards-start")){
            e.target.closest(".before__cards-start").classList.add("animate__fadeOutLeftBig");
            instructionFade();
            musicPlay();
          }
          else if(e.target.closest(".before__cards-info")){
            e.target.closest(".before__cards-info").classList.add("animate__fadeOutLeftBig");
            drawStuff();
          }
        }
        else{
          inActiveGame()
        }
      }

      function drawStuff() {

        let i = 0,
            gameTime = 0,
            gameInterval: number | undefined;

        let gameOver = false;
        let score = 0;

        function setGameOver(view: CanvasView) {
          gameOver = false;
          // @ts-ignore
          play(winBuffer);
          main.classList.add("animate__fadeOutLeftBig");
          bgMusic.loop = false;
          if(!shield()){
            inActiveGame()
          }
        }

        function setGameWin(view: CanvasView) {
          clearInterval(gameInterval);
          if(gameTime < 45){
            let restTime = 45 - gameTime;
            score = score + (restTime * 100);
            view.drawScore(score);
            gameOver = false;
          }
          else{
            let aboveTime = gameTime - 45;
            score = score - ( aboveTime * 10);
            view.drawScore(score);
            gameOver = false;
          }
          // @ts-ignore
          play(winBuffer);
          main.classList.add("animate__fadeOutLeftBig");
          bgMusic.loop = false;
          if(!shield()){
            inActiveGame()
          }
        }
        const fps = 60;

        function gameLoop(
            view: CanvasView,
            bricks: Brick[],
            paddle: Paddle,
            ball: Ball,
            collision: Collision
        ) {
          setTimeout(function(){
            view.clear();
            view.drawBricks(bricks);
            view.drawSprite(paddle);
            view.drawSprite(ball);
            // Move Ball
            ball.moveBall();

            // Move paddle and check so it won't exit the playfield
            if (
                (paddle.isMovingLeft && paddle.pos.x > 0) ||
                (paddle.isMovingRight && paddle.pos.x < view.canvas.width - paddle.width)
            ) {
              paddle.movePaddle();
            }

            collision.checkBallCollision(ball, paddle, view);
            const collidingBrick = collision.isCollidingBricks(ball, bricks);
            if (collidingBrick) {
              score = Math.floor(score + (100 * 0.80));
              view.drawScore(score);
              play(bumpBuffer);
            }

            // Game Over when ball leaves playField

            const  startBall = function() {
              ball.startBall();
              if(!shield()){
                inActiveGame()
              }
              // @ts-ignore
              canvas.removeEventListener('click',  startBall,true)
            }

            if(ball.pos.y == BALL_STARTY && ball.speed.x == 0){
              ball.pos.x = paddle.pos.x + (PADDLE_WIDTH / 2 - BALL_SIZE / 2);
            }
            if (ball.pos.y > view.canvas.height - 150) {
              i = i + 1;
              if(i <= LIVES - 1 ){
                ball.pos.y = BALL_STARTY;
                ball.stopBall();
                livesImages[i].remove()
                play(liveBuffer);
                // @ts-ignore
                canvas.addEventListener('click',  startBall,true)
              }
              else{
                clearInterval(gameInterval);
                gameOver = true;
              }
            }
            // If game won, set gameOver and display win
            if (bricks.length === 0){
              return setGameWin(view);
            }
            // Return if gameover and don't run the requestAnimationFrame
            if (gameOver) return setGameOver(view);

            requestAnimationFrame(() => gameLoop(view, bricks, paddle, ball, collision));
          }, 1000 / fps);
        }

        function startGame(view: CanvasView) {

          // Reset displays
          score = 0;
          view.drawScore(0);
          startGameSound.play();
          playMuted(bumpBuffer);
          playMuted(liveBuffer);
          playMuted(winBuffer);
          gameInterval = setInterval(() => {
            gameTime += 1
          }, 1000);

          // Create a collision instance
          const collision = new Collision();
          // Create all bricks
          const bricks = createBricks();
          // Create a Ball

          const ball = new Ball(
              BALL_SPEED,
              BALL_SIZE,
              { x: BALL_STARTX, y: BALL_STARTY },
              BALL_IMAGES[1]
          );
          // Create a Paddle

          const paddle = new Paddle(
              PADDLE_SPEED,
              PADDLE_WIDTH,
              PADDLE_HEIGHT,
              {
                x: PADDLE_STARTX,
                // @ts-ignore
                y: canvas.height - PADDLE_HEIGHT - 150
              },
              PADDLE_IMAGES[1]
          );

          gameLoop(view, bricks, paddle, ball, collision);
        }

// Create a new view
        const view = new CanvasView('#playField');
        view.initStartButton(startGame);
      }
    }

})();
