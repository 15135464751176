// security

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const codeParam = urlParams.get('code');
const xhr = new XMLHttpRequest();
let xhrStatus = false;
export function inActiveGame() {
    const body = document.querySelector('body'));
    body.innerHTML = "Twoja Sesja wygasła";
    body.classList.add("inactive");
}
export function shield(){
    xhr.open('POST','./index.php' + "?code=" + codeParam,false);
    xhr.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200 && this.responseText === 'active') {
            xhrStatus = true;
            console.log(this.responseText);
        }
        else{
            xhrStatus = false;
        }
    };
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.send('function=hello');
    return xhrStatus;
}
